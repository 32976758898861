import mobileAppImage from '@/assets/images/mobile-overview.png';
import sliderFolders from '@/assets/images/slider-folder.png';
import sliderReading from '@/assets/images/slider-reading.png';
import sliderScan from '@/assets/images/slider-scan.png';
import sliderUpload from '@/assets/images/slider-upload.png';
import { Button } from '@/components/ui/button';
import { useCallback, useEffect, useRef, useState } from 'react';

const features = [
  {
    id: 0,
    title: 'Easy to use.',
    image: mobileAppImage,
    alt: 'Easy to use view',
  },
  {
    id: 1,
    title: 'Scan Papers.',
    image: sliderScan,
    alt: 'Scan papers view',
  },
  {
    id: 2,
    title: 'Upload Documents.',
    image: sliderUpload,
    alt: 'Upload documents view',
  },
  {
    id: 3,
    title: 'Reading View.',
    image: sliderReading,
    alt: 'Reading view',
  },
  {
    id: 4,
    title: 'Folders.',
    image: sliderFolders,
    alt: 'Folders view',
  },
];

const AppSliderMobile = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLElement>(null);

  // Function to handle index changes with wrapping
  const nextSlide = useCallback(() => {
    setActiveIndex((current) => (current + 1) % features.length);
  }, []);

  // Set up intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]) {
          setIsVisible(entries[0].isIntersecting);
        }
      },
      {
        threshold: 0.2, // Start when 20% of the component is visible
      },
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  // Auto-sliding functionality
  useEffect(() => {
    if (isPaused || !isVisible) return;

    const intervalId = setInterval(nextSlide, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isPaused, isVisible, nextSlide]);

  // Handle manual slide changes
  const handleSlideClick = (index: number) => {
    setActiveIndex(index);
    setIsPaused(true);

    setTimeout(() => {
      setIsPaused(false);
    }, 5000);
  };

  return (
    <section
      ref={sectionRef}
      className="relative min-h-dvh bg-white"
      aria-label="App Features"
    >
      <div className="top-0 z-10 bg-white px-4 pt-12 sm:px-6">
        <div className="text-center">
          <h2 className="mb-4 text-4xl font-bold" id="slider-heading">
            How the app works
          </h2>
        </div>
      </div>

      <div className="mt-6 flex flex-col items-center px-4">
        {/* Feature titles column */}
        <div className="flex w-full flex-col space-y-6">
          {features.map((feature, index) => (
            <button
              key={feature.id}
              type="button"
              onClick={() => {
                handleSlideClick(index);
              }}
              className={`text-center text-xl font-bold transition-all duration-300 ${
                index === activeIndex
                  ? 'scale-110 text-blue-600'
                  : 'text-gray-400'
              }`}
            >
              {feature.title}
            </button>
          ))}
        </div>

        {/* Image container */}
        <div className="relative mt-12 h-[500px] w-[260px] overflow-hidden rounded-2xl">
          {features.map((feature, index) => (
            <div
              key={feature.id}
              className={`absolute inset-0 transition-all duration-500 ease-in-out ${
                index === activeIndex
                  ? 'translate-y-0 opacity-100'
                  : index < activeIndex
                    ? '-translate-y-full opacity-0'
                    : 'translate-y-full opacity-0'
              }`}
            >
              <div className="size-full rounded-2xl bg-white">
                <img
                  src={feature.image.src}
                  alt={feature.alt}
                  className="size-full object-contain p-2"
                  loading="lazy"
                  width={380}
                  height={800}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Try Now Button */}
        <div className="z-[100] mt-20 flex w-full justify-center pb-20">
          <Button
            asChild
            variant="ghost"
            aria-label="Try app for free"
            className="relative rounded-full bg-[#102087] px-6 py-4 text-base font-medium text-white transition-all duration-300 hover:-translate-y-1 hover:shadow-lg sm:px-24 sm:py-8 sm:text-lg"
          >
            <a href="/app/continue" target="_blank" rel="noopener noreferrer">
              Try Now for Free
            </a>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AppSliderMobile;
